@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
}

#scrollContainer, #scrollContainer2 {
  scroll-behavior: smooth;
}

/* Hide the scrollbar in webkit browsers */
#scrollContainer::-webkit-scrollbar,
#scrollContainer2::-webkit-scrollbar {
  width: 0; /* Set a width for the scrollbar track (optional) */
}

#scrollContainer::-webkit-scrollbar-thumb,
#scrollContainer2::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the thumb */
}

#banner p,
#banner h1,
#banner h2,
#banner h3,
#banner h4,
#banner h5,
#banner h6,
#banner ul.nav-menu > li {
  filter: drop-shadow(0 0 5px rgba(0,0,0,0.3));
}


.container:not(#career) {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	z-index: 999;
  height: 100%;
	min-height: calc(100vh - 60px);
  min-height: calc(100svh - 60px);
	width: 100%;
	-webkit-box-sizing: border-box;
    box-sizing: border-box;
	max-width: 100vw;
  border-bottom: 1px solid rgb(214, 211, 209);
}

@media only screen and (min-width: 640px) {
	.container {
		max-width: 100%;
	}
}

@media only screen and (min-width: 1024px) {
  #about {
    max-width: 100% !important;
    background: url('./assets//images/aboutSection.jpeg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
